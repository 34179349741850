
import { useState } from "react";
import { Menu, X, Globe, LogIn, UserCircle2 } from "lucide-react";
import { motion } from "framer-motion";
import { Button } from "./ui/button";
import { useAuth } from "@/contexts/AuthContext";
import { Link } from "react-router-dom";
import { AuthModal } from "./auth/AuthModal";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [language, setLanguage] = useState<"RO" | "EN">("RO");
  const { user, signOut } = useAuth();

  const menuItems = [
    { name: "Servicii", href: "#servicii" },
    { name: "Despre Noi", href: "#despre" },
    { name: "Contact", href: "#contact" },
    { name: "Resurse", href: "#resurse" },
  ];

  const translations = {
    RO: {
      login: "Autentificare",
      dashboard: "Panou de Control",
      signOut: "Deconectare"
    },
    EN: {
      login: "Login",
      dashboard: "Dashboard",
      signOut: "Sign Out"
    }
  };

  const toggleLanguage = () => {
    const newLanguage = language === "RO" ? "EN" : "RO";
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10 backdrop-blur-md border-b border-purple-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                Elma AI
              </Link>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-8">
              {menuItems.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                >
                  {item.name}
                </a>
              ))}
              <Button
                variant="ghost"
                size="sm"
                className="flex items-center gap-2"
                onClick={toggleLanguage}
              >
                <Globe className="h-4 w-4" />
                {language}
              </Button>
              {user ? (
                <div className="flex items-center space-x-4">
                  <Link to="/dashboard">
                    <Button variant="ghost" className="flex items-center gap-2">
                      <UserCircle2 className="h-4 w-4" />
                      {translations[language].dashboard}
                    </Button>
                  </Link>
                  <Button
                    variant="outline"
                    className="flex items-center gap-2 border-purple-200 hover:bg-purple-50"
                    onClick={handleSignOut}
                  >
                    {translations[language].signOut}
                  </Button>
                </div>
              ) : (
                <Button 
                  className="flex items-center gap-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 text-white"
                  onClick={() => setIsAuthModalOpen(true)}
                >
                  <LogIn className="h-4 w-4" />
                  {translations[language].login}
                </Button>
              )}
            </div>

            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsOpen(!isOpen)}
                aria-label="Toggle menu"
              >
                {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </Button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="md:hidden"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white/80 backdrop-blur-md border-b border-purple-100">
              {menuItems.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-3 py-2 text-gray-600 hover:text-purple-600 hover:bg-purple-50 rounded-md transition-colors"
                  onClick={() => setIsOpen(false)}
                >
                  {item.name}
                </a>
              ))}
              <Button
                variant="ghost"
                size="sm"
                className="flex items-center gap-2 ml-2"
                onClick={toggleLanguage}
              >
                <Globe className="h-4 w-4" />
                {language}
              </Button>
              {user ? (
                <>
                  <Link to="/dashboard" className="block w-full" onClick={() => setIsOpen(false)}>
                    <Button variant="ghost" className="flex items-center gap-2 w-full">
                      <UserCircle2 className="h-4 w-4" />
                      {translations[language].dashboard}
                    </Button>
                  </Link>
                  <Button
                    variant="outline"
                    className="flex items-center gap-2 w-full border-purple-200 hover:bg-purple-50"
                    onClick={handleSignOut}
                  >
                    {translations[language].signOut}
                  </Button>
                </>
              ) : (
                <Button 
                  className="flex items-center gap-2 w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 text-white"
                  onClick={() => setIsAuthModalOpen(true)}
                >
                  <LogIn className="h-4 w-4" />
                  {translations[language].login}
                </Button>
              )}
            </div>
          </motion.div>
        )}
      </nav>

      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => setIsAuthModalOpen(false)} 
      />
    </>
  );
};

export default Navigation;
