import { History, MessageSquare, Star } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Tables } from "@/integrations/supabase/types";

interface ConsultationsSectionProps {
  isLoading: boolean;
  consultations: Tables<"consultations">[] | null;
  translations: Record<string, string>;
  onSelectConsultation?: (consultation: Tables<"consultations">) => void;
}

const ConsultationsSection = ({
  isLoading,
  consultations,
  translations: t,
  onSelectConsultation
}: ConsultationsSectionProps) => {
  if (isLoading) {
    return (
      <div className="space-y-2">
        {[1, 2, 3].map((i) => (
          <Skeleton key={i} className="h-16" />
        ))}
      </div>
    );
  }

  if (!consultations?.length) {
    return (
      <Card className="bg-white/80 backdrop-blur-sm border-indigo-100 shadow-lg">
        <CardContent className="p-4">
          <p className="text-center text-gray-500">{t.noConsultations}</p>
        </CardContent>
      </Card>
    );
  }

  const sortedConsultations = [...consultations].sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  return (
    <div className="space-y-4 max-h-[600px] overflow-y-auto pr-2">
      {sortedConsultations.map((consultation) => (
        <Card 
          key={consultation.id} 
          className="transform transition-all duration-300 hover:scale-[1.01] bg-white border-gray-100 shadow-sm hover:shadow-md cursor-pointer h-[180px] overflow-hidden hover:h-auto hover:absolute hover:z-10 hover:right-2 hover:left-0"
          onClick={() => onSelectConsultation?.(consultation)}
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 py-3 px-4">
            <div className="flex items-center gap-2">
              <MessageSquare className="h-5 w-5 text-indigo-600" />
              <CardTitle className="text-base font-medium text-gray-900">
                {consultation.type === 'ai' ? 'AI' : consultation.type.charAt(0).toUpperCase() + consultation.type.slice(1)}
              </CardTitle>
            </div>
            <span className="text-sm text-indigo-600">
              {new Date(consultation.created_at).toLocaleDateString()}
            </span>
          </CardHeader>
          <CardContent className="py-2 px-4">
            <p className="font-medium text-base text-gray-900 mb-2 line-clamp-1">{consultation.question}</p>
            <div 
              className="text-sm text-gray-600 prose prose-indigo max-w-none line-clamp-3"
              dangerouslySetInnerHTML={{ 
                __html: consultation.answer.replace(
                  /(https?:\/\/[^\s]+)/g, 
                  '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 underline">$1</a>'
                )
              }}
            />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ConsultationsSection;