import Hero from "@/components/Hero";
import Features from "@/components/Features";
import Navigation from "@/components/Navigation";
import Services from "@/components/Services";
import About from "@/components/About";
import Contact from "@/components/Contact";
import Resources from "@/components/Resources";
import { motion } from "framer-motion";
import { useAuth } from "@/contexts/AuthContext";

const Index = () => {
  const { user } = useAuth();
  
  console.log("Index component rendering, user:", user?.id);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-white"
    >
      <Navigation />
      <div className="pt-16">
        <Hero />
        <Features />
        <Services />
        <About />
        <Resources />
        <Contact />
      </div>
    </motion.div>
  );
};

export default Index;