import { useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Camera } from "lucide-react";

interface ProfileAvatarProps {
  photoUrl?: string;
  fullName?: string;
  onPhotoUpdate: () => Promise<void>;
}

const ProfileAvatar = ({ photoUrl, fullName, onPhotoUpdate }: ProfileAvatarProps) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || !e.target.files[0]) return;

      const file = e.target.files[0];
      const fileExt = file.name.split(".").pop();
      const filePath = `${user?.id}/${crypto.randomUUID()}.${fileExt}`;

      setUploadingPhoto(true);

      const { error: uploadError } = await supabase.storage
        .from("profile_photos")
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from("profile_photos")
        .getPublicUrl(filePath);

      const { error: updateError } = await supabase
        .from("user_profiles")
        .update({ photo_url: publicUrl })
        .eq("id", user?.id);

      if (updateError) throw updateError;

      await onPhotoUpdate();
      
      toast({
        title: "Фото профиля обновлено",
        description: "Ваше новое фото профиля успешно загружено",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ошибка при загрузке фото",
        description: error.message,
      });
    } finally {
      setUploadingPhoto(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <Avatar className="w-32 h-32">
        <AvatarImage src={photoUrl} />
        <AvatarFallback>
          {fullName?.[0]?.toUpperCase() || user?.email?.[0]?.toUpperCase()}
        </AvatarFallback>
      </Avatar>
      <div className="relative">
        <input
          type="file"
          accept="image/*"
          onChange={handlePhotoUpload}
          className="hidden"
          id="photo-upload"
          disabled={uploadingPhoto}
        />
        <Button
          variant="outline"
          size="sm"
          className="gap-2"
          asChild
          disabled={uploadingPhoto}
        >
          <label htmlFor="photo-upload" className="cursor-pointer">
            <Camera className="h-4 w-4" />
            {uploadingPhoto ? "Загрузка..." : "Изменить фото"}
          </label>
        </Button>
      </div>
    </div>
  );
};

export default ProfileAvatar;