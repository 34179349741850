import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { Alert, AlertDescription } from "./ui/alert";
import { MessageSquare, Loader } from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { Tables } from "@/integrations/supabase/types";
import { ScrollArea } from "./ui/scroll-area";
import { ChatMessage } from "./chat/ChatMessage";
import { ChatInput } from "./chat/ChatInput";
import { ConsultationHeader } from "./chat/ConsultationHeader";
import { TranslationType } from "./chat/types";

interface AiChatProps {
  language: "RO" | "EN" | "RU";
  selectedConsultation?: Tables<"consultations"> | null;
  onClearSelectedConsultation?: () => void;
}

const translations: Record<AiChatProps["language"], TranslationType> = {
  RO: {
    placeholder: "Întrebați despre afacerea dvs. în România...",
    send: "Trimite",
    sending: "Se trimite...",
    response: "Răspuns:",
    willUse: "Voi folosi",
    marked: "Marcat pentru utilizare",
    error: {
      title: "Eroare",
      description: "Nu s-a putut obține răspunsul de la AI",
      tryAgain: "Vă rugăm să încercați din nou"
    },
    sections: {
      mainAnswer: "Răspuns Principal",
      examples: "Exemple Practice",
      legal: "Referințe Legale",
      resources: "Resurse Adiționale"
    },
    continueDialog: "Continuați dialogul...",
    clearDialog: "Închide dialogul"
  },
  EN: {
    placeholder: "Ask about your business in Romania...",
    send: "Send",
    sending: "Sending...",
    response: "Response:",
    willUse: "Will Use",
    marked: "Marked for use",
    error: {
      title: "Error",
      description: "Failed to get AI response",
      tryAgain: "Please try again"
    },
    sections: {
      mainAnswer: "Main Answer",
      examples: "Practical Examples",
      legal: "Legal References",
      resources: "Additional Resources"
    },
    continueDialog: "Continue the dialog...",
    clearDialog: "Clear dialog"
  },
  RU: {
    placeholder: "Спросите о вашем бизнесе в Румынии...",
    send: "Отправить",
    sending: "Отправка...",
    response: "Ответ:",
    willUse: "Буду использовать",
    marked: "Отмечено для использования",
    error: {
      title: "Ошибка",
      description: "Не удалось получить ответ от ИИ",
      tryAgain: "Пожалуйста, попробуйте еще раз"
    },
    sections: {
      mainAnswer: "Основной Ответ",
      examples: "Практические Примеры",
      legal: "Правовые Ссылки",
      resources: "Дополнительные Ресурсы"
    },
    continueDialog: "Продолжить диалог...",
    clearDialog: "Закрыть диалог"
  }
};

const AiChat = ({ language, selectedConsultation, onClearSelectedConsultation }: AiChatProps) => {
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [consultationId, setConsultationId] = useState<string | null>(null);
  const [willUse, setWillUse] = useState(false);
  const [businessId, setBusinessId] = useState<string | null>(null);
  const { toast } = useToast();
  const { user } = useAuth();
  const t = translations[language];

  useEffect(() => {
    if (selectedConsultation) {
      setResponse(selectedConsultation.answer);
      setConsultationId(selectedConsultation.id);
      setWillUse(selectedConsultation.will_use || false);
      setMessage("");
    } else {
      setResponse("");
      setConsultationId(null);
      setWillUse(false);
    }
  }, [selectedConsultation]);

  useEffect(() => {
    const fetchOrCreateBusinessProfile = async () => {
      if (!user) {
        console.log('No user found, skipping business profile fetch');
        return;
      }

      try {
        console.log('Fetching business profile for user:', user.id);
        const { data: profile, error: profileError } = await supabase
          .from('business_profiles')
          .select('id')
          .eq('user_id', user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Error fetching business profile:', profileError);
          toast({
            title: "Error",
            description: "Failed to fetch business profile",
            variant: "destructive"
          });
          return;
        }

        if (profile) {
          console.log('Business profile found:', profile);
          setBusinessId(profile.id);
        } else {
          console.log('No business profile found, creating one...');
          const { data: newProfile, error: createError } = await supabase
            .from('business_profiles')
            .insert([{
              user_id: user.id,
              company_name: 'Default Company',
              fiscal_code: 'DEFAULT123',
              registration_number: 'REG123'
            }])
            .select()
            .single();

          if (createError) {
            console.error('Error creating business profile:', createError);
            toast({
              title: "Error",
              description: "Failed to create business profile",
              variant: "destructive"
            });
            return;
          }

          console.log('Created new business profile:', newProfile);
          setBusinessId(newProfile.id);
          toast({
            title: "Success",
            description: "Business profile created successfully",
          });
        }
      } catch (error) {
        console.error('Error in fetchOrCreateBusinessProfile:', error);
        toast({
          title: "Error",
          description: "An unexpected error occurred",
          variant: "destructive"
        });
      }
    };

    fetchOrCreateBusinessProfile();
  }, [user, toast]);

  const handleWillUse = async () => {
    if (!consultationId) return;

    try {
      const { error: updateError } = await supabase
        .from('consultations')
        .update({ will_use: !willUse })
        .eq('id', consultationId);

      if (updateError) throw updateError;

      setWillUse(!willUse);
      toast({
        title: !willUse ? t.marked : "Updated",
        description: !willUse ? "Response marked as useful" : "Mark removed",
      });
    } catch (error) {
      console.error('Error updating will_use status:', error);
      toast({
        title: "Error",
        description: "Failed to update status",
        variant: "destructive"
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) {
      console.log('Message is empty, not submitting');
      return;
    }
    if (!businessId) {
      console.log('No business ID found, not submitting');
      toast({
        title: "Error",
        description: "Please wait while we set up your business profile",
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      console.log("Starting AI request with message:", message);
      console.log("Business ID:", businessId);
      
      const requestBody = {
        message: message.trim()
      };
      
      console.log("Sending request body:", JSON.stringify(requestBody));
      
      const { data, error: apiError } = await supabase.functions.invoke('ask-ai', {
        body: requestBody
      });

      if (apiError) {
        console.error("API Error:", apiError);
        throw new Error(apiError.message || "Failed to get AI response");
      }

      if (!data?.choices?.[0]?.message?.content) {
        console.error("Invalid response format:", data);
        throw new Error("Invalid response format from AI");
      }

      const aiResponse = data.choices[0].message.content;
      setResponse(aiResponse);

      console.log("Saving consultation to database...");
      const { data: consultationData, error: dbError } = await supabase
        .from('consultations')
        .insert([{
          business_id: businessId,
          type: 'ai',
          question: message.trim(),
          answer: aiResponse,
          will_use: false
        }])
        .select()
        .single();

      if (dbError) {
        console.error("Database Error:", dbError);
        toast({
          title: "Warning",
          description: "Got AI response but failed to save it. Please try again later.",
          variant: "destructive"
        });
      } else {
        console.log("Consultation saved successfully:", consultationData);
        setConsultationId(consultationData.id);
        setWillUse(false);
      }

    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setError(error.message);
      toast({
        title: t.error.title,
        description: t.error.description,
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`max-w-4xl mx-auto flex flex-col ${
      !selectedConsultation && !response 
        ? 'h-[400px]' // Empty state - compact height
        : selectedConsultation 
          ? 'min-h-[600px]' // Viewing history - taller minimum height
          : 'h-[calc(100vh-200px)]' // Active chat - full height
    }`}>
      {selectedConsultation && (
        <ConsultationHeader 
          onClear={onClearSelectedConsultation || (() => {})} 
          translations={t} 
        />
      )}

      <ScrollArea className="flex-grow mb-4 px-4">
        <div className="space-y-6">
          {selectedConsultation && (
            <div className="space-y-6">
              <ChatMessage content={selectedConsultation.question} isQuestion />
              <ChatMessage content={selectedConsultation.answer} />
            </div>
          )}

          {response && !isLoading && !selectedConsultation && (
            <div className="space-y-6">
              <ChatMessage content={message} isQuestion />
              <ChatMessage content={response} />
            </div>
          )}

          {isLoading && (
            <div className="flex flex-col items-center justify-center p-8 space-y-4">
              <div className="relative w-24 h-24">
                <div className="absolute top-0 left-0 w-full h-full border-4 border-indigo-200 rounded-full animate-[spin_3s_linear_infinite]">
                  <Loader className="w-8 h-8 text-indigo-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-[spin_2s_linear_infinite]" />
                </div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <MessageSquare className="w-8 h-8 text-indigo-600 animate-[pulse_1.5s_ease-in-out_infinite]" />
                </div>
              </div>
              <span className="text-indigo-600 font-medium animate-[pulse_1.5s_ease-in-out_infinite]">
                {t.sending}
              </span>
            </div>
          )}
        </div>
      </ScrollArea>

      <ChatInput
        message={message}
        setMessage={setMessage}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        consultationId={consultationId}
        willUse={willUse}
        handleWillUse={handleWillUse}
        translations={t}
        businessId={businessId}
        placeholder={selectedConsultation?.question}
      />

      {error && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>
            {error}
            <br />
            {t.error.tryAgain}
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default AiChat;