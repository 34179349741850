import { formatLinks } from "@/lib/utils";

interface ChatMessageProps {
  content: string;
  isQuestion?: boolean;
}

export const ChatMessage = ({ content, isQuestion = false }: ChatMessageProps) => {
  return (
    <div
      className={`p-4 rounded-lg ${
        isQuestion
          ? "bg-indigo-50 border border-indigo-100"
          : "bg-white border border-gray-100 shadow-sm"
      }`}
    >
      <div
        className="prose prose-indigo max-w-none"
        dangerouslySetInnerHTML={{ __html: formatLinks(content) }}
      />
    </div>
  );
};