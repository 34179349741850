import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Card } from "@/components/ui/card";
import { History, Building2, MessageSquare } from "lucide-react";
import AiChat from "@/components/AiChat";
import Navigation from "@/components/Navigation";
import BusinessProfileSection from "@/components/dashboard/BusinessProfileSection";
import ConsultationsSection from "@/components/dashboard/ConsultationsSection";
import { Tables } from "@/integrations/supabase/types";

const translations = {
  RO: {
    dashboard: "Panou de Control",
    companyInfo: "Informații despre Companie",
    company: "Companie",
    regNumber: "Nr. Înregistrare",
    businessType: "Tip Afacere",
    fiscalCode: "Cod Fiscal",
    employees: "Angajați",
    employeeCount: "Număr de Angajați",
    revenue: "Venit Anual",
    lastYear: "Ultimul An",
    notSpecified: "Nespecificat",
    consultations: "Istoric Consultări",
    noCompanyInfo: "Nu s-au găsit informații despre companie",
    noConsultations: "Istoricul consultărilor este gol",
    aiAssistant: "Asistent AI",
  },
  EN: {
    dashboard: "Dashboard",
    companyInfo: "Company Information",
    company: "Company",
    regNumber: "Reg. Number",
    businessType: "Business Type",
    fiscalCode: "Fiscal Code",
    employees: "Employees",
    employeeCount: "Employee Count",
    revenue: "Annual Revenue",
    lastYear: "Last Year",
    notSpecified: "Not Specified",
    consultations: "Consultation History",
    noCompanyInfo: "Company information not found",
    noConsultations: "Consultation history is empty",
    aiAssistant: "AI Assistant",
    viewProfile: "View Profile",
  }
};

const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const language = localStorage.getItem("language") as "RO" | "EN" || "RO";
  const t = translations[language];
  const [selectedConsultation, setSelectedConsultation] = useState<Tables<"consultations"> | null>(null);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const { data: businessProfile, isLoading: isLoadingProfile } = useQuery({
    queryKey: ["businessProfile", user?.id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("business_profiles")
        .select("*")
        .eq("user_id", user?.id)
        .maybeSingle();

      if (error) throw error;
      return data;
    },
    enabled: !!user,
  });

  const { data: consultations, isLoading: isLoadingConsultations } = useQuery({
    queryKey: ["consultations", businessProfile?.id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("consultations")
        .select("*")
        .eq("business_id", businessProfile?.id)
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
    enabled: !!businessProfile?.id,
  });

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-purple-50">
      <Navigation />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-12">
        <div className="max-w-7xl mx-auto">
          {/* Main AI Assistant Section */}
          <div className="mb-8">
            <Card className="overflow-hidden bg-white/80 backdrop-blur-sm border-indigo-100 shadow-xl shadow-indigo-100/20">
              <div className="p-6">
                <div className="flex items-center gap-2 mb-4">
                  <MessageSquare className="h-6 w-6 text-indigo-600" />
                  <h2 className="text-2xl font-semibold text-gray-800">
                    {t.aiAssistant}
                  </h2>
                </div>
                <AiChat 
                  language={language} 
                  selectedConsultation={selectedConsultation}
                  onClearSelectedConsultation={() => setSelectedConsultation(null)}
                />
              </div>
            </Card>
          </div>

          {/* Secondary Information in Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Company Information Section */}
            <section className="animate-fade-up">
              <h2 className="text-2xl font-semibold text-gray-800 flex items-center gap-2 mb-6">
                <Building2 className="h-6 w-6 text-indigo-600" />
                {t.companyInfo}
              </h2>
              <div className="transform transition-all duration-300 hover:scale-[1.01]">
                <BusinessProfileSection
                  isLoading={isLoadingProfile}
                  businessProfile={businessProfile}
                  translations={t}
                />
              </div>
            </section>

            {/* Consultations Section */}
            <section className="animate-fade-up delay-100">
              <h2 className="text-2xl font-semibold text-gray-800 flex items-center gap-2 mb-6">
                <History className="h-6 w-6 text-indigo-600" />
                {t.consultations}
              </h2>
              <ConsultationsSection
                isLoading={isLoadingConsultations}
                consultations={consultations}
                translations={t}
                onSelectConsultation={setSelectedConsultation}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;