
import { motion } from "framer-motion";
import { FileText, Download, ExternalLink } from "lucide-react";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";

const translations = {
  RO: {
    title: "Resurse Utile",
    subtitle: "Descoperă resurse gratuite care te vor ajuta să-ți dezvolți afacerea",
    newsletter: "Abonează-te la newsletter pentru a primi cele mai noi resurse",
    subscribe: "Abonează-te",
    resources: [
      {
        title: "Ghid Startup",
        description: "Ghid complet pentru înființarea unei societăți comerciale",
        type: "download",
        icon: FileText,
        action: "Descarcă"
      },
      {
        title: "Calculator Taxe",
        description: "Calculator online pentru estimarea taxelor și impozitelor",
        type: "tool",
        icon: Download,
        action: "Accesează"
      },
      {
        title: "Blog de Business",
        description: "Articole și sfaturi pentru antreprenori",
        type: "link",
        icon: ExternalLink,
        action: "Citește mai mult"
      }
    ]
  },
  EN: {
    title: "Useful Resources",
    subtitle: "Discover free resources that will help you grow your business",
    newsletter: "Subscribe to our newsletter to receive the latest resources",
    subscribe: "Subscribe",
    resources: [
      {
        title: "Startup Guide",
        description: "Complete guide for establishing a commercial company",
        type: "download",
        icon: FileText,
        action: "Download"
      },
      {
        title: "Tax Calculator",
        description: "Online calculator for estimating taxes and duties",
        type: "tool",
        icon: Download,
        action: "Access"
      },
      {
        title: "Business Blog",
        description: "Articles and tips for entrepreneurs",
        type: "link",
        icon: ExternalLink,
        action: "Read more"
      }
    ]
  }
};

const Resources = () => {
  const language = localStorage.getItem("language") as "RO" | "EN" || "RO";
  const t = translations[language];

  return (
    <section id="resurse" className="py-24 px-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">{t.title}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {t.subtitle}
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {t.resources.map((resource, index) => (
            <motion.div
              key={resource.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card className="h-full hover:shadow-lg transition-shadow">
                <CardContent className="p-6">
                  <resource.icon className="w-12 h-12 text-purple-600 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">{resource.title}</h3>
                  <p className="text-gray-600 mb-4">{resource.description}</p>
                  <Button
                    variant="outline"
                    className="w-full border-purple-200 hover:bg-purple-50"
                  >
                    {resource.action}
                  </Button>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="mt-16 text-center"
        >
          <p className="text-gray-600 mb-4">
            {t.newsletter}
          </p>
          <Button className="bg-purple-600 hover:bg-purple-700 text-white">
            {t.subscribe}
          </Button>
        </motion.div>
      </div>
    </section>
  );
};

export default Resources;
