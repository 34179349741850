import { motion } from "framer-motion";
import { Scale, BookOpen, Shield, ArrowRight, FileText, Database, MessageSquare } from "lucide-react";

const translations = {
  RO: {
    features: "Caracteristici",
    title: "Soluții Complete pentru IMM-ul Tău",
    learnMore: "Află mai multe",
    items: [
      {
        title: "Consultanță Juridică",
        description: "Sfaturi actualizate conform legislației românești în vigoare pentru IMM-uri.",
        icon: Scale,
      },
      {
        title: "Asistență Financiară",
        description: "Ghidare în contabilitate, taxe și management financiar adaptat pentru România.",
        icon: BookOpen,
      },
      {
        title: "Conformitate Garantată",
        description: "Informații verificate și aliniate cu reglementările actuale din România.",
        icon: Shield,
      },
      {
        title: "Documente Legislative",
        description: "Acces la referințe și documente oficiale actualizate.",
        icon: FileText,
      },
      {
        title: "Istoric Conversații",
        description: "Păstrarea contextului pentru asistență personalizată continuă.",
        icon: Database,
      },
      {
        title: "Răspunsuri Inteligente",
        description: "Sfaturi practice bazate pe AI și legislația românească.",
        icon: MessageSquare,
      }
    ]
  },
  EN: {
    features: "Features",
    title: "Complete Solutions for Your SME",
    learnMore: "Learn more",
    items: [
      {
        title: "Legal Consulting",
        description: "Updated advice according to current Romanian legislation for SMEs.",
        icon: Scale,
      },
      {
        title: "Financial Assistance",
        description: "Guidance in accounting, taxes and financial management adapted for Romania.",
        icon: BookOpen,
      },
      {
        title: "Guaranteed Compliance",
        description: "Verified information aligned with current Romanian regulations.",
        icon: Shield,
      },
      {
        title: "Legislative Documents",
        description: "Access to updated official references and documents.",
        icon: FileText,
      },
      {
        title: "Conversation History",
        description: "Context preservation for continuous personalized assistance.",
        icon: Database,
      },
      {
        title: "Intelligent Responses",
        description: "Practical advice based on AI and Romanian legislation.",
        icon: MessageSquare,
      }
    ]
  }
};

const Features = () => {
  const language = localStorage.getItem("language") as "RO" | "EN" || "RO";
  const t = translations[language];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <section className="relative py-16 sm:py-24 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-purple-50/50 via-white to-purple-50/50 overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]" />
        <div className="absolute right-0 bottom-0 -z-10 h-[300px] w-[300px] rounded-full bg-purple-100 opacity-20 blur-[100px]" />
        <div className="absolute left-0 top-0 -z-10 h-[300px] w-[300px] rounded-full bg-indigo-100 opacity-20 blur-[100px]" />
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12 sm:mb-16 relative">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="inline-flex items-center px-4 py-1.5 text-sm font-medium bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-full shadow-lg shadow-purple-500/25"
          >
            {t.features}
            <motion.span
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ repeat: Infinity, duration: 2 }}
              className="ml-2"
            >
              ✨
            </motion.span>
          </motion.span>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="mt-4 text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight bg-gradient-to-r from-gray-900 via-purple-900 to-gray-800 bg-clip-text text-transparent px-4"
          >
            {t.title}
          </motion.h2>
        </div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-50px" }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
        >
          {t.items.map((feature, index) => (
            <motion.div
              key={feature.title}
              variants={itemVariants}
              whileHover={{ y: -5, transition: { duration: 0.2 } }}
              className="group relative"
            >
              <div className="absolute -inset-px bg-gradient-to-r from-purple-500 to-pink-500 rounded-2xl opacity-0 group-hover:opacity-100 blur transition duration-300" />
              <div className="relative h-full p-6 sm:p-8 bg-white/80 backdrop-blur-sm rounded-2xl border border-purple-100/50 shadow-sm hover:shadow-xl transition-all duration-300">
                <div className="absolute inset-0 bg-gradient-to-br from-purple-50/50 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
                
                <div className="relative flex flex-col h-full">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="p-2.5 rounded-xl bg-purple-100/50 group-hover:bg-purple-100 transition-colors duration-300">
                      <feature.icon className="w-6 h-6 sm:w-7 sm:h-7 text-purple-600 group-hover:scale-110 transition-transform duration-300" />
                    </div>
                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-900">{feature.title}</h3>
                  </div>
                  
                  <p className="text-base sm:text-lg text-gray-600 mb-6 grow">{feature.description}</p>
                  
                  <button className="group/btn inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-700 transition-colors mt-auto">
                    {t.learnMore}
                    <ArrowRight className="ml-2 w-4 h-4 group-hover/btn:translate-x-1 transition-transform" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Features;