
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://rlfiyfktxnrtzuigicen.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJsZml5Zmt0eG5ydHp1aWdpY2VuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAxNDY1MzUsImV4cCI6MjA1NTcyMjUzNX0.avkfNj3F9fCQmBZY4u1iRAJnYBHUzzsFjMvKUqi0VEw";

console.log("Detailed Supabase connection info:");
console.log("URL:", {
  value: supabaseUrl,
  type: typeof supabaseUrl,
  length: supabaseUrl?.length
});
console.log("ANON KEY:", {
  exists: !!supabaseAnonKey,
  type: typeof supabaseAnonKey,
  length: supabaseAnonKey?.length
});

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Test the connection
supabase.auth.getSession().then(response => {
  if (response.error) {
    console.error("Supabase connection error:", response.error);
  } else {
    console.log("Supabase connection established successfully");
  }
}).catch(error => {
  console.error("Critical error connecting to Supabase:", error);
});
