import { useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { User } from "lucide-react";
import ProfileAvatar from "./ProfileAvatar";
import ProfileForm from "./ProfileForm";
import ProfileInfo from "./ProfileInfo";

const ProfileSection = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [isEditing, setIsEditing] = useState(false);

  const { data: profile, refetch } = useQuery({
    queryKey: ["userProfile", user?.id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("user_profiles")
        .select("*")
        .eq("id", user?.id)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!user,
  });

  const [formData, setFormData] = useState({
    full_name: profile?.full_name || "",
    phone: profile?.phone || "",
    position: profile?.position || "",
  });

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from("user_profiles")
        .update(formData)
        .eq("id", user?.id);

      if (error) throw error;
      
      setIsEditing(false);
      await refetch();
      
      toast({
        title: "Профиль обновлен",
        description: "Ваши изменения успешно сохранены",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ошибка при сохранении",
        description: error.message,
      });
    }
  };

  const handlePhotoUpdate = async () => {
    await refetch();
  };

  return (
    <div className="w-full">
      <Card className="overflow-hidden">
        <CardHeader>
          <CardTitle className="text-2xl font-bold flex items-center gap-2">
            <User className="h-6 w-6 text-purple-600" />
            Профиль
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col md:flex-row gap-6 items-start">
            <div className="w-full md:w-1/3">
              <ProfileAvatar
                photoUrl={profile?.photo_url}
                fullName={profile?.full_name}
                onPhotoUpdate={handlePhotoUpdate}
              />
            </div>
            <div className="flex-1 min-w-0">
              {isEditing ? (
                <ProfileForm
                  formData={formData}
                  onFormChange={(data) => setFormData((prev) => ({ ...prev, ...data }))}
                  onCancel={() => setIsEditing(false)}
                  onSave={handleSave}
                />
              ) : (
                <ProfileInfo
                  email={user?.email}
                  fullName={profile?.full_name}
                  phone={profile?.phone}
                  position={profile?.position}
                  onEdit={() => {
                    setFormData({
                      full_name: profile?.full_name || "",
                      phone: profile?.phone || "",
                      position: profile?.position || "",
                    });
                    setIsEditing(true);
                  }}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProfileSection;