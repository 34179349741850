import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";
import { Phone, Mail } from "lucide-react";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type AuthMethod = "email" | "phone";

export const AuthModal = ({ isOpen, onClose }: AuthModalProps) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+40");
  const [password, setPassword] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [authMethod, setAuthMethod] = useState<AuthMethod>("email");
  const { signIn, signInWithPhone, verifyOtp, signUp, signUpWithPhone } = useAuth();
  const { toast } = useToast();

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    
    if (!value.startsWith("+40")) {
      value = "+40";
    }
    
    value = value.replace(/[^\d+]/g, "");
    
    if (value.length > 12) {
      value = value.slice(0, 12);
    }
    
    setPhone(value);
  };

  const handlePhoneVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await verifyOtp(phone, otpCode);
      if (error) throw error;

      // После успешной верификации пытаемся войти с сохраненным паролем
      const savedPassword = localStorage.getItem(`phone_auth_${phone}`);
      if (savedPassword) {
        const { error: signInError } = await signInWithPhone(phone, savedPassword);
        if (signInError) throw signInError;
      }

      toast({
        title: "Verificare reușită",
        description: "Numărul de telefon a fost verificat cu succes.",
      });
      onClose();
    } catch (error) {
      console.error("Eroare la verificare:", error);
      toast({
        variant: "destructive",
        title: "Eroare la verificare",
        description: "Codul introdus nu este valid. Vă rugăm să încercați din nou.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (authMethod === "email") {
        await signIn(email, password);
        toast({
          title: "Autentificare reușită",
          description: "Bine ați revenit!",
        });
        onClose();
      } else {
        // Получаем сохраненный пароль для телефона
        const savedPassword = localStorage.getItem(`phone_auth_${phone}`);
        if (!savedPassword) {
          // Если пароль не найден, отправляем код подтверждения для новой регистрации
          const { data, error } = await signUpWithPhone(phone);
          if (error) throw error;
          
          setShowOtpInput(true);
          toast({
            title: "Cod de verificare necesar",
            description: "Vă rugăm să introduceți codul primit prin SMS.",
          });
          return;
        }

        // Пытаемся войти с сохраненным паролем
        const { data, error } = await signInWithPhone(phone, savedPassword);
        if (error) {
          // Если ошибка входа, возможно пароль устарел, пробуем новую регистрацию
          const { data: signUpData, error: signUpError } = await signUpWithPhone(phone);
          if (signUpError) throw signUpError;
          
          setShowOtpInput(true);
          toast({
            title: "Cod de verificare necesar",
            description: "Vă rugăm să introduceți codul primit prin SMS.",
          });
          return;
        }

        if (data.session) {
          toast({
            title: "Autentificare reușită",
            description: "Bine ați revenit!",
          });
          onClose();
        }
      }
    } catch (error) {
      console.error("Eroare de autentificare:", error);
      toast({
        variant: "destructive",
        title: "Eroare de autentificare",
        description: "Verificați datele introduse",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (authMethod === "email") {
        const { error } = await signUp(email, password);
        if (error) throw error;
        toast({
          title: "Înregistrare reușită",
          description: "Vă rugăm să verificați emailul pentru confirmare.",
        });
        onClose();
      } else {
        // Отправляем код подтверждения на телефон
        const { data, error } = await signUpWithPhone(phone);
        if (error) throw error;

        setShowOtpInput(true);
        toast({
          title: "Cod de verificare necesar",
          description: "Vă rugăm să introduceți codul primit prin SMS.",
        });
      }
    } catch (error) {
      console.error("Eroare la înregistrare:", error);
      toast({
        variant: "destructive",
        title: "Eroare la înregistrare",
        description: "Încercați din nou mai târziu.",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderAuthMethodSelector = () => (
    <div className="flex gap-2 mb-4">
      <Button
        type="button"
        variant={authMethod === "email" ? "default" : "outline"}
        className="flex-1 gap-2"
        onClick={() => {
          setAuthMethod("email");
          setShowOtpInput(false);
        }}
      >
        <Mail className="w-4 h-4" />
        Email
      </Button>
      <Button
        type="button"
        variant={authMethod === "phone" ? "default" : "outline"}
        className="flex-1 gap-2"
        onClick={() => {
          setAuthMethod("phone");
          setShowOtpInput(false);
          setPhone("+40");
        }}
      >
        <Phone className="w-4 h-4" />
        Telefon
      </Button>
    </div>
  );

  if (showOtpInput) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold text-center">
              Verificare cod SMS
            </DialogTitle>
          </DialogHeader>
          
          <form onSubmit={handlePhoneVerification} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="otp" className="text-sm font-medium">
                Cod de verificare
              </label>
              <Input
                id="otp"
                type="text"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
                required
                placeholder="Introduceți codul primit prin SMS"
                className="text-center text-2xl tracking-widest"
                maxLength={6}
              />
            </div>
            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600"
              disabled={loading}
            >
              {loading ? "Se procesează..." : "Verifică codul"}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-center">
            Autentificare / Înregistrare
          </DialogTitle>
        </DialogHeader>
        
        <Tabs defaultValue="login" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="login">Autentificare</TabsTrigger>
            <TabsTrigger value="register">Înregistrare</TabsTrigger>
          </TabsList>

          <TabsContent value="login">
            <form onSubmit={handleSignIn} className="space-y-4">
              {renderAuthMethodSelector()}
              {authMethod === "email" ? (
                <>
                  <div className="space-y-2">
                    <label htmlFor="email-login" className="text-sm font-medium">
                      Email
                    </label>
                    <Input
                      id="email-login"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="nume@companie.ro"
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="password-login" className="text-sm font-medium">
                      Parolă
                    </label>
                    <Input
                      id="password-login"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      placeholder="••••••••"
                    />
                  </div>
                </>
              ) : (
                <div className="space-y-2">
                  <label htmlFor="phone-login" className="text-sm font-medium">
                    Număr de telefon
                  </label>
                  <Input
                    id="phone-login"
                    type="tel"
                    value={phone}
                    onChange={handlePhoneChange}
                    required
                    placeholder="+40 712 345 678"
                    minLength={12}
                  />
                </div>
              )}
              <Button
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600"
                disabled={loading}
              >
                {loading ? "Se procesează..." : "Autentificare"}
              </Button>
            </form>
          </TabsContent>

          <TabsContent value="register">
            <form onSubmit={handleSignUp} className="space-y-4">
              {renderAuthMethodSelector()}
              {authMethod === "email" ? (
                <>
                  <div className="space-y-2">
                    <label htmlFor="email-register" className="text-sm font-medium">
                      Email
                    </label>
                    <Input
                      id="email-register"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="nume@companie.ro"
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="password-register" className="text-sm font-medium">
                      Parolă
                    </label>
                    <Input
                      id="password-register"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      placeholder="••••••••"
                    />
                  </div>
                </>
              ) : (
                <div className="space-y-2">
                  <label htmlFor="phone-register" className="text-sm font-medium">
                    Număr de telefon
                  </label>
                  <Input
                    id="phone-register"
                    type="tel"
                    value={phone}
                    onChange={handlePhoneChange}
                    required
                    placeholder="+40 712 345 678"
                    minLength={12}
                  />
                </div>
              )}
              <Button
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600"
                disabled={loading}
              >
                {loading ? "Se procesează..." : authMethod === "phone" ? "Trimite cod SMS" : "Înregistrare"}
              </Button>
            </form>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};
