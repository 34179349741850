import { motion } from "framer-motion";
import { CheckCircle } from "lucide-react";

const translations = {
  RO: {
    title: "Despre SmartSME",
    description: "Cu o experiență vastă în domeniul consultanței pentru IMM-uri, echipa noastră oferă servicii complete de consiliere și suport pentru dezvoltarea afacerii tale. Înțelegem provocările cu care se confruntă antreprenorii români și oferim soluții adaptate nevoilor specifice ale pieței locale.",
    values: [
      "Expertiză dovedită în consultanță de business",
      "Soluții personalizate pentru fiecare client",
      "Echipă de profesioniști dedicați",
      "Actualizări constante cu legislația în vigoare"
    ],
    stats: {
      experience: "Ani de experiență în consultanță",
      clients: "Clienți mulțumiți",
      success: "Rată de succes în proiecte"
    }
  },
  EN: {
    title: "About SmartSME",
    description: "With extensive experience in SME consulting, our team provides comprehensive advisory services and support for your business development. We understand the challenges faced by Romanian entrepreneurs and offer solutions tailored to the specific needs of the local market.",
    values: [
      "Proven business consulting expertise",
      "Customized solutions for each client",
      "Team of dedicated professionals",
      "Constant updates with current legislation"
    ],
    stats: {
      experience: "Years of consulting experience",
      clients: "Satisfied clients",
      success: "Project success rate"
    }
  }
};

const About = () => {
  const language = localStorage.getItem("language") as "RO" | "EN" || "RO";
  const t = translations[language];

  return (
    <section id="despre" className="py-24 px-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-6">{t.title}</h2>
            <p className="text-gray-600 mb-8">{t.description}</p>
            <div className="space-y-4">
              {t.values.map((value, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="flex items-center gap-3"
                >
                  <CheckCircle className="text-purple-600 w-5 h-5 flex-shrink-0" />
                  <span className="text-gray-700">{value}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="relative"
          >
            <div className="aspect-square rounded-2xl bg-gradient-to-br from-purple-100 to-purple-50 p-8">
              <div className="w-full h-full rounded-xl bg-white/50 backdrop-blur-sm border border-purple-100 p-6">
                <div className="space-y-6">
                  <div className="text-4xl font-bold text-purple-600">10+</div>
                  <div className="text-gray-700">{t.stats.experience}</div>
                  <div className="text-4xl font-bold text-purple-600">500+</div>
                  <div className="text-gray-700">{t.stats.clients}</div>
                  <div className="text-4xl font-bold text-purple-600">95%</div>
                  <div className="text-gray-700">{t.stats.success}</div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;