import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";

interface ProfileInfoProps {
  email?: string;
  fullName?: string;
  phone?: string;
  position?: string;
  onEdit: () => void;
}

const ProfileInfo = ({ email, fullName, phone, position, onEdit }: ProfileInfoProps) => {
  return (
    <div className="space-y-4">
      <div className="overflow-hidden">
        <label className="text-sm text-gray-500 block mb-1">Email</label>
        <p className="text-lg font-medium truncate">{email}</p>
      </div>
      <div className="overflow-hidden">
        <label className="text-sm text-gray-500 block mb-1">Полное имя</label>
        <p className="text-lg font-medium truncate">{fullName || "Не указано"}</p>
      </div>
      <div className="overflow-hidden">
        <label className="text-sm text-gray-500 block mb-1">Телефон</label>
        <p className="text-lg font-medium truncate">{phone || "Не указано"}</p>
      </div>
      <div className="overflow-hidden">
        <label className="text-sm text-gray-500 block mb-1">Должность</label>
        <p className="text-lg font-medium truncate">{position || "Не указано"}</p>
      </div>
      <Button
        variant="outline"
        onClick={onEdit}
        className="gap-2 mt-4"
      >
        <Pencil className="h-4 w-4" />
        Редактировать профиль
      </Button>
    </div>
  );
};

export default ProfileInfo;