import { Button } from "../ui/button";
import { X } from "lucide-react";
import { TranslationType } from "./types";

interface ConsultationHeaderProps {
  onClear: () => void;
  translations: TranslationType;
}

export const ConsultationHeader = ({ onClear, translations: t }: ConsultationHeaderProps) => {
  return (
    <div className="flex items-center justify-between bg-indigo-50/50 p-4 rounded-lg border border-indigo-100 mb-4">
      <p className="text-sm text-indigo-600 font-medium">{t.continueDialog}</p>
      <Button
        variant="ghost"
        size="sm"
        onClick={onClear}
        className="text-indigo-600 hover:text-indigo-800"
      >
        <X className="h-4 w-4 mr-1" />
        {t.clearDialog}
      </Button>
    </div>
  );
};