import { Building2, Users, BadgeDollarSign } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Tables } from "@/integrations/supabase/types";

interface BusinessProfileSectionProps {
  isLoading: boolean;
  businessProfile: Tables<"business_profiles"> | null;
  translations: Record<string, string>;
}

const BusinessProfileSection = ({ 
  isLoading, 
  businessProfile, 
  translations: t 
}: BusinessProfileSectionProps) => {
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {[1, 2, 3].map((i) => (
          <Skeleton key={i} className="h-32" />
        ))}
      </div>
    );
  }

  if (!businessProfile) {
    return (
      <Card className="bg-white/80 backdrop-blur-sm border-indigo-100 shadow-lg">
        <CardContent className="p-6">
          <p className="text-center text-gray-500">{t.noCompanyInfo}</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <Card className="transform transition-all duration-300 hover:scale-[1.02] bg-gradient-to-br from-white to-indigo-50/50 border-indigo-100 shadow-lg shadow-indigo-100/20">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium text-indigo-900">{t.company}</CardTitle>
          <Building2 className="h-4 w-4 text-indigo-600" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold text-gray-900">{businessProfile.company_name}</div>
          <p className="text-xs text-indigo-600 mt-1">{t.regNumber}: {businessProfile.registration_number}</p>
          <p className="text-xs text-indigo-600">{t.fiscalCode}: {businessProfile.fiscal_code}</p>
        </CardContent>
      </Card>

      <Card className="transform transition-all duration-300 hover:scale-[1.02] bg-gradient-to-br from-white to-indigo-50/50 border-indigo-100 shadow-lg shadow-indigo-100/20">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium text-indigo-900">{t.employees}</CardTitle>
          <Users className="h-4 w-4 text-indigo-600" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold text-gray-900">{businessProfile.employee_count || "0"}</div>
          <p className="text-xs text-indigo-600 mt-1">{t.employeeCount}</p>
          <p className="text-xs text-indigo-600">{businessProfile.business_type || t.notSpecified}</p>
        </CardContent>
      </Card>

      <Card className="transform transition-all duration-300 hover:scale-[1.02] bg-gradient-to-br from-white to-indigo-50/50 border-indigo-100 shadow-lg shadow-indigo-100/20">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium text-indigo-900">{t.revenue}</CardTitle>
          <BadgeDollarSign className="h-4 w-4 text-indigo-600" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold text-gray-900">
            {businessProfile.annual_revenue ? `${businessProfile.annual_revenue} MDL` : t.notSpecified}
          </div>
          <p className="text-xs text-indigo-600 mt-1">{t.lastYear}</p>
        </CardContent>
      </Card>
    </div>
  );
};

export default BusinessProfileSection;