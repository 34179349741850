import { motion } from "framer-motion";
import { Card, CardContent } from "./ui/card";
import { Scale, Calculator, FileText, MessageSquare, Building2, Users } from "lucide-react";

const translations = {
  RO: {
    title: "Serviciile Noastre",
    subtitle: "Oferim o gamă completă de servicii pentru afacerea ta",
    services: [
      {
        title: "Consultanță Juridică",
        description: "Asistență juridică specializată pentru IMM-uri",
        icon: Scale
      },
      {
        title: "Consultanță Fiscală",
        description: "Optimizare fiscală și conformitate",
        icon: Calculator
      },
      {
        title: "Documente și Contracte",
        description: "Generare și verificare documente",
        icon: FileText
      },
      {
        title: "Asistent Virtual 24/7",
        description: "Răspunsuri instant la întrebările tale",
        icon: MessageSquare
      },
      {
        title: "Înființare Firmă",
        description: "Ghidare completă pentru start-up",
        icon: Building2
      },
      {
        title: "Resurse Umane",
        description: "Consultanță în managementul personalului",
        icon: Users
      }
    ]
  },
  EN: {
    title: "Our Services",
    subtitle: "We offer a complete range of services for your business",
    services: [
      {
        title: "Legal Consulting",
        description: "Specialized legal assistance for SMEs",
        icon: Scale
      },
      {
        title: "Tax Consulting",
        description: "Tax optimization and compliance",
        icon: Calculator
      },
      {
        title: "Documents & Contracts",
        description: "Document generation and verification",
        icon: FileText
      },
      {
        title: "24/7 Virtual Assistant",
        description: "Instant answers to your questions",
        icon: MessageSquare
      },
      {
        title: "Company Formation",
        description: "Complete guidance for startups",
        icon: Building2
      },
      {
        title: "Human Resources",
        description: "Personnel management consulting",
        icon: Users
      }
    ]
  }
};

const Services = () => {
  const language = localStorage.getItem("language") as "RO" | "EN" || "RO";
  const t = translations[language];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <section id="servicii" className="relative py-16 sm:py-24 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-purple-50/50 to-white/80 overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]" />
        <div className="absolute right-1/4 top-1/4 -z-10 h-64 w-64 rounded-full bg-purple-100 opacity-20 blur-[100px]" />
        <div className="absolute left-1/4 bottom-1/4 -z-10 h-64 w-64 rounded-full bg-indigo-100 opacity-20 blur-[100px]" />
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12 sm:mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="relative inline-block text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-4"
          >
            <span className="relative z-10">{t.title}</span>
            <motion.span
              className="absolute -right-6 -top-6 text-4xl"
              animate={{ rotate: [0, 20, 0], scale: [1, 1.2, 1] }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              ✨
            </motion.span>
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto px-4 font-medium"
          >
            {t.subtitle}
          </motion.p>
        </div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-50px" }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
        >
          {t.services.map((service, index) => (
            <motion.div
              key={service.title}
              variants={itemVariants}
              whileHover={{ y: -5, scale: 1.02 }}
              className="group"
            >
              <Card className="relative h-full overflow-hidden hover:shadow-xl transition-all duration-300 bg-white/80 backdrop-blur-sm border-purple-100/50">
                <CardContent className="p-6 sm:p-8">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-50/50 via-white/50 to-pink-50/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  
                  <div className="relative flex flex-col h-full">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="p-2.5 rounded-xl bg-purple-100/50 group-hover:bg-purple-100 transition-colors duration-300">
                        <service.icon className="w-6 h-6 sm:w-7 sm:h-7 text-purple-600 group-hover:scale-110 transition-transform duration-300" />
                      </div>
                      <h3 className="text-xl sm:text-2xl font-semibold bg-gradient-to-r from-purple-900 to-pink-900 bg-clip-text text-transparent">
                        {service.title}
                      </h3>
                    </div>
                    
                    <p className="text-base sm:text-lg text-gray-600 mb-4 grow">
                      {service.description}
                    </p>
                    
                    <div className="relative mt-auto pt-4 before:absolute before:top-0 before:left-0 before:right-0 before:h-px before:bg-gradient-to-r before:from-transparent before:via-purple-200 before:to-transparent">
                      <motion.button
                        whileHover={{ x: 5 }}
                        className="flex items-center text-base font-medium text-purple-600 hover:text-purple-700 transition-colors"
                      >
                        {t.services} →
                      </motion.button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Services;