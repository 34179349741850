import { motion } from "framer-motion";
import { Phone, Mail, MapPin } from "lucide-react";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";

const translations = {
  RO: {
    title: "Contactează-ne",
    subtitle: "Suntem aici să te ajutăm. Contactează-ne pentru o consultație gratuită și află cum putem contribui la succesul afacerii tale.",
    phone: "Telefon",
    email: "Email",
    address: "Adresă",
    schedule: "Programează o consultație gratuită"
  },
  EN: {
    title: "Contact Us",
    subtitle: "We're here to help. Contact us for a free consultation and find out how we can contribute to your business success.",
    phone: "Phone",
    email: "Email",
    address: "Address",
    schedule: "Schedule a Free Consultation"
  }
};

const Contact = () => {
  const language = localStorage.getItem("language") as "RO" | "EN" || "RO";
  const t = translations[language];

  const contactInfo = [
    {
      icon: Phone,
      title: t.phone,
      details: "+40 721 234 567",
      link: "tel:+40721234567"
    },
    {
      icon: Mail,
      title: t.email,
      details: "contact@ecorg.ai",
      link: "mailto:contact@ecorg.ai"
    },
    {
      icon: MapPin,
      title: t.address,
      details: "Str. Victoriei 25, București",
      link: "https://maps.google.com"
    }
  ];

  return (
    <section id="contact" className="py-24 px-4 bg-gradient-to-b from-white to-purple-50">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">{t.title}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {t.subtitle}
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {contactInfo.map((info, index) => (
            <motion.div
              key={info.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card className="h-full hover:shadow-lg transition-shadow">
                <CardContent className="p-6 text-center">
                  <info.icon className="w-8 h-8 text-purple-600 mx-auto mb-4" />
                  <h3 className="text-lg font-semibold mb-2">{info.title}</h3>
                  <a
                    href={info.link}
                    className="text-gray-600 hover:text-purple-600 transition-colors"
                  >
                    {info.details}
                  </a>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="max-w-xl mx-auto text-center"
        >
          <Button
            size="lg"
            className="bg-purple-600 hover:bg-purple-700 text-white"
          >
            {t.schedule}
          </Button>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;