import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { Star, Loader } from "lucide-react";
import { TranslationType } from "./types";

interface ChatInputProps {
  message: string;
  setMessage: (message: string) => void;
  handleSubmit: (e: React.FormEvent) => void;
  isLoading: boolean;
  consultationId: string | null;
  willUse: boolean;
  handleWillUse: () => void;
  translations: TranslationType;
  businessId: string | null;
  placeholder?: string;
}

export const ChatInput = ({
  message,
  setMessage,
  handleSubmit,
  isLoading,
  consultationId,
  willUse,
  handleWillUse,
  translations: t,
  businessId,
  placeholder
}: ChatInputProps) => {
  return (
    <div className="sticky bottom-0 bg-white/80 backdrop-blur-sm p-4 rounded-lg border border-indigo-100 shadow-lg">
      <form onSubmit={handleSubmit} className="space-y-4">
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={placeholder || t.placeholder}
          className="min-h-[100px] resize-none"
        />
        <div className="flex justify-between items-center">
          <Button 
            type="submit" 
            disabled={isLoading || !message.trim() || !businessId}
            className="bg-indigo-600 hover:bg-indigo-700"
          >
            {isLoading ? t.sending : t.send}
          </Button>
          {consultationId && (
            <Button
              variant="outline"
              size="sm"
              onClick={handleWillUse}
              className={`flex items-center gap-2 transition-all duration-300 ${willUse ? 'bg-indigo-50 scale-105' : ''}`}
            >
              <Star className={`h-4 w-4 transition-colors duration-300 ${willUse ? 'fill-indigo-500 text-indigo-500' : 'text-indigo-400'}`} />
              {willUse ? t.marked : t.willUse}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};