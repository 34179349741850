import { motion } from "framer-motion";
import { ArrowRight, Globe, Calculator, Scale } from "lucide-react";
import { Button } from "./ui/button";
import { useState, useEffect } from "react";

const translations = {
  RO: {
    title: "Întreab-o pe Elma!",
    subtitle: "Elma știe răspunsul la toate întrebările tale despre afaceri. Primești consultanță 24/7.",
    legalSupport: "Asistență juridică",
    accounting: "Contabilitate și taxe",
    connectAssistant: "Începe chat cu Elma",
    learnMore: "Află mai multe"
  },
  EN: {
    title: "Ask Elma!",
    subtitle: "Elma knows the answers to all your business questions. Get expert advice 24/7.",
    legalSupport: "Legal Support",
    accounting: "Accounting & Taxes",
    connectAssistant: "Start chat with Elma",
    learnMore: "Learn More"
  }
};

const Hero = () => {
  const [language, setLanguage] = useState<"RO" | "EN">("RO");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") as "RO" | "EN";
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const t = translations[language];

  return (
    <section className="relative min-h-[100dvh] flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-indigo-50/80 via-purple-50/80 to-pink-50/80 overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]" />
        <div className="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-purple-200 opacity-20 blur-[100px]" />
        <div className="absolute left-20 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-indigo-200 opacity-20 blur-[100px]" />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative w-full max-w-3xl mx-auto space-y-6 text-center"
      >
        {/* Logo and badge */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="flex items-center justify-center gap-3 mb-8"
        >
          <div className="relative">
            <Globe className="w-8 h-8 sm:w-10 sm:h-10 text-purple-600" />
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.5, type: "spring", stiffness: 200 }}
              className="absolute -right-1 -top-1 w-3 h-3 bg-green-400 rounded-full border-2 border-white"
            />
          </div>
          <span className="inline-flex items-center px-4 py-1.5 text-base sm:text-lg font-medium bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full shadow-lg shadow-purple-500/25">
            Elma AI
            <motion.span
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ repeat: Infinity, duration: 2 }}
              className="ml-2 text-yellow-300"
            >
              ✨
            </motion.span>
          </span>
        </motion.div>

        {/* Title with gradient and animation */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8 }}
          className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tight bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900 bg-clip-text text-transparent text-balance leading-tight px-4"
        >
          {t.title}
        </motion.h1>

        {/* Subtitle with improved typography */}
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
          className="text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto text-balance leading-relaxed font-medium px-4"
        >
          {t.subtitle}
        </motion.p>

        {/* CTA Buttons with enhanced mobile design */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          className="flex flex-col gap-4 sm:flex-row sm:gap-6 justify-center mt-8 px-4"
        >
          <Button
            size="lg"
            className="relative overflow-hidden group bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 text-white shadow-lg shadow-purple-500/25 transform hover:scale-105 transition-all duration-300 text-lg py-6 sm:py-4"
          >
            <motion.span
              initial={{ x: "100%" }}
              animate={{ x: "-100%" }}
              transition={{ repeat: Infinity, duration: 15, ease: "linear" }}
              className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent"
            />
            {t.connectAssistant}
            <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
          </Button>
          <Button
            size="lg"
            variant="outline"
            className="border-2 border-purple-200 hover:bg-purple-50/50 transform hover:scale-105 transition-all duration-300 text-lg py-6 sm:py-4"
          >
            {t.learnMore}
          </Button>
        </motion.div>

        {/* Feature badges with glass effect */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
          className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-8 mt-12"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="flex items-center gap-3 px-5 py-3 bg-white/70 backdrop-blur-sm rounded-2xl shadow-sm border border-purple-100/50 w-full sm:w-auto justify-center"
          >
            <Scale className="w-5 h-5 text-purple-600" />
            <span className="text-base font-medium text-gray-800">{t.legalSupport}</span>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="flex items-center gap-3 px-5 py-3 bg-white/70 backdrop-blur-sm rounded-2xl shadow-sm border border-purple-100/50 w-full sm:w-auto justify-center"
          >
            <Calculator className="w-5 h-5 text-purple-600" />
            <span className="text-base font-medium text-gray-800">{t.accounting}</span>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
