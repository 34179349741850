import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import ProfileSection from "@/components/dashboard/ProfileSection";
import Navigation from "@/components/Navigation";

const Profile = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (!user) return null;

  return (
    <>
      <Navigation />
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
        <div className="pt-20 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <ProfileSection />
        </div>
      </div>
    </>
  );
};

export default Profile;