import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Save, X } from "lucide-react";

interface ProfileFormData {
  full_name: string;
  phone: string;
  position: string;
}

interface ProfileFormProps {
  formData: ProfileFormData;
  onFormChange: (data: Partial<ProfileFormData>) => void;
  onCancel: () => void;
  onSave: () => void;
}

const ProfileForm = ({ formData, onFormChange, onCancel, onSave }: ProfileFormProps) => {
  return (
    <div className="space-y-6 p-4">
      <div>
        <label className="text-sm font-medium block mb-2">Полное имя</label>
        <Input
          value={formData.full_name}
          onChange={(e) => onFormChange({ full_name: e.target.value })}
          placeholder="Введите ваше полное имя"
          className="w-full"
        />
      </div>
      <div>
        <label className="text-sm font-medium block mb-2">Телефон</label>
        <Input
          value={formData.phone}
          onChange={(e) => onFormChange({ phone: e.target.value })}
          placeholder="+40 XXX XXX XXX"
          className="w-full"
        />
      </div>
      <div>
        <label className="text-sm font-medium block mb-2">Должность</label>
        <Input
          value={formData.position}
          onChange={(e) => onFormChange({ position: e.target.value })}
          placeholder="Например: Генеральный директор"
          className="w-full"
        />
      </div>
      <div className="flex justify-end gap-2 pt-4">
        <Button
          variant="outline"
          onClick={onCancel}
          className="gap-2"
        >
          <X className="h-4 w-4" />
          Отмена
        </Button>
        <Button
          onClick={onSave}
          className="gap-2"
        >
          <Save className="h-4 w-4" />
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default ProfileForm;